import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { headers } from "../Template/TemplateAPI";

export const getAllUsers = createAsyncThunk("/auth/allUser", async () => {
  try {
    const response = await axios.get(Baseurl + "/auth/allUser",);
    return response.data?.data;
  } catch (error) {
    console.log(error);
  }
});

export const SignupFun = createAsyncThunk(
  "auth/register",
  async (data: any) => {
    try {
      const response = await axios.post(Baseurl + `/auth/register`, data);
      console.log("error signup api false", response?.data);
      if (response?.data?.success) {
        toast.success("Account Created Successfully, Login First");
      } else {
        console.log("error signup api false", response?.data);
      }
      return response.data.user;
    } catch (error) {
      console.log("error auth api", error);
      toast.error("Server Error");
    }
  }
);
export const LoginFun = createAsyncThunk("auth/login", async (data: any) => {
  try {
    const response = await axios.post(Baseurl + `/auth/login`, data);
    console.log("error signup api false", response?.data);
    if (response?.data?.success) {
      localStorage.setItem("token", response?.data?.token);
      localStorage.setItem("user", response?.data?.data?._id);
    } else {
      console.log("error login api false", response?.data);
    }
    return response.data.user;
  } catch (error) {
    toast.error("Server Error");
    console.log("error auth api", error);
  }
});
export const getMeFun = createAsyncThunk("auth/getme", async (data: any) => {
  console.log("data getMeFun", data);

  try {
    const response = await axios.post(
      Baseurl + `/auth/getme`,
      data
    );

    if (response?.data?.success) {
      return response.data.data;
    }
  } catch (error) {
    console.log("error auth getme api", error);
  }
});

export const updateProfile = createAsyncThunk(
  "auth/updatedetails",
  async (data: any) => {
    try {
      console.log("data update", data);

      const response = await axios.put(
        Baseurl + `/auth/updatedetails`,

        data
      );
      console.log("ressss", response.data);
      if (response.data?.success) {
        toast.success("Profile Updated Successfully");
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const checkEmail = createAsyncThunk(
  "auth/forgotPassword",
  async (data: any) => {
    let d = {
      email: data.email,
    };
    try {
      const response = await axios.post(Baseurl + `/auth/forgotPassword`, d);
      // console.log("e",response?.data);

      if (response?.data?.success) {
        toast.success("OTP Code Send On Your Gmail Successfully");
        localStorage.setItem("user-id", response?.data?.user?._id);
        data.navigate("/otp");
      } else {
        console.log("error signup api false", response?.data);
      }
      return response.data.user;
    } catch (error) {
      console.log("error auth api", error);
      toast.error("Server Error");
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatepassword",
  async (data: any) => {
    try {
      const response = await axios.put(
        Baseurl + `/auth/updatepassword`,

        data
      );
      console.log("ressss", response.data);
      if (response.data?.success) {
        toast.success("Password Updated Successfully");
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const sendOtp = createAsyncThunk(
  "auth/resetpassword",
  async (data: any) => {
    let d = {
      user_id: data._id,
      otp: data.otp,
    };
    try {
      const response = await axios.put(
        Baseurl + `/auth/resetpassword/${d?.user_id}`,
        d
      );

      if (response?.data?.success) {
        toast.success("OTP Code Send On Your Gmail Successfully");
        data.navigate("/forgot");
      } else {
        console.log("error signup api false", response?.data);
        toast.error("OTP is incorrect");
      }
      return response.data.user;
    } catch (error) {
      console.log("error auth api", error);
      toast.error("Server Error");
    }
  }
);

// =========update role ========

export const updaterole = createAsyncThunk(
  "auth/updatedetails",
  async (data: any) => {
    console.log("update data", data);

    try {
      const response = await axios.put(
        Baseurl + `/auth/updatedetails`,

        data
      );
      console.log("ressss", response.data);
      if (response.data?.success) {
        toast.success("Updated Successfully");
      }
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const GoogleAuth = createAsyncThunk(
  "auth/GoogleAuth",
  async (data: any) => {
    // console.log("data action", data);

    try {
      const response = await axios.post(
        Baseurl + `/auth/GoogleAuth`,
        data
      );
      console.log("ressss", response.data);
      // if (response.data?.success) {
      //   toast.success("Updated Successfully");
      // }
      return response.data;
    } catch (error: any) {
      if (error?.response.data.success === false) {
        toast.error(error?.response.data.message);
      }
      else {
        toast.error("Server Error");
      }
      console.log("error in google auth", error);
    }
  }
);
export const LoginFunGoogle = createAsyncThunk("auth/loginGoogle", async (data: any) => {
  try {
    let data1 = {
      email: data.email,
      sub: data.sub
    }


    const response = await axios.post(Baseurl + `/auth/loginGoogle`, data1);


    if (response?.data?.success) {
      localStorage.setItem("token", response?.data?.data?.email);
      localStorage.setItem("user", response?.data?.data?._id);
    } else {
      console.log("error login api false", response?.data);
    }
    return response?.data?.data;
  } catch (error) {
    toast.error("Server Error");
    console.log("error auth api", error);
  }
});